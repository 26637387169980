import React from "react";
import FormikTextInput from "components/FormikTextInput/FormikTextInput";
import FormikTextArea from "components/FormikTextArea/FormikTextArea";
import { formatCommaSeparatedString } from "utils/formatCommaSeparatedString";
import FlexBox from "components/FlexBox/FlexBox";
import styled, { css } from "styled-components/macro";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

type OwnerGroupFormContentProps = {};

const OwnerGroupFormContent: React.FC<OwnerGroupFormContentProps> = () => {
  const { t } = useTranslation();
  const { setFieldValue, submitForm } = useFormikContext();

  return (
    <FlexBox spacing={4} direction="column">
      <StyledGroupTitle>{t(`Enter group of owners`)}</StyledGroupTitle>
      <FormikTextInput label={t(`Group name`)} fieldName="groupName" />
      <FlexBox direction={"column"}>
        <FormikTextArea
          label={"List members of the group"}
          fieldName="groupList"
          rows={6}
          onBlur={(evt) => {
            const formattedCsv = formatCommaSeparatedString(evt.target.value);
            setFieldValue("groupList", formattedCsv);
            submitForm();
          }}
        />
        {t(`Use commas to separate each member of the group`)}
      </FlexBox>
    </FlexBox>
  );
};

const StyledGroupTitle = styled.div(
  ({ theme }) => css`
    color: ${theme.palette.objective.dark.fiordland};
    font-weight: 700;
  `
);

export default OwnerGroupFormContent;
