import { Cradle } from "services/serviceContainer.types";
import get from "lodash/get";
import { IContactEntity, IContactPersonalInfoFormValues } from "models/Contact.model";
import toString from "lodash/toString";
import toInteger from "lodash/toInteger";
import { IContactService } from "services/contact/ContactService.types";
import { IAddressEntity } from "models/Address.model";
import { ServiceError, ServiceErrorCode } from "services/ServiceError";
import { IQualificationEntity } from "models/Qualification.model";
import toUpper from "lodash/toUpper";

export class ContactService implements IContactService {
  private readonly apiClient: Cradle["apiClient"];
  private readonly i18n: Cradle["i18n"];

  constructor(args: { apiClient: Cradle["apiClient"]; i18n: Cradle["i18n"] }) {
    this.apiClient = args.apiClient;
    this.i18n = args.i18n;
  }

  public async fetchContactsForCurrentUserOrganisation() {
    const response = await this.apiClient.protectedApi.get(`/user/organisation/contacts`);
    const jsonArr = get(response.data, "contacts");
    if (!Array.isArray(jsonArr)) {
      throw new ServiceError(ServiceErrorCode.ServerError, this.i18n.t(`Failed to fetch contacts`));
    }

    const contacts: IContactEntity[] = jsonArr.map((json) => this.parseContact(json));
    return contacts;
  }

  public async fetchOrganisationContactById(id: number): Promise<IContactEntity> {
    const response = await this.apiClient.protectedApi.get(`/user/organisation/contacts/${id}`);
    const json = response.data;
    const contact = this.parseContact(json);
    return contact;
  }

  public async deleteOrganisationContactById(id: number): Promise<void> {
    await this.apiClient.protectedApi.delete(`/user/organisation/contacts/${id}`);
  }

  public async createContact(args: {
    formValues: Partial<IContactPersonalInfoFormValues>;
    qualificationValues: IQualificationEntity[];
  }): Promise<IContactEntity> {
    const payload: Partial<IContactEntity> = {
      firstName: args.formValues.firstName,
      lastName: args.formValues.lastName,
      organisation: args.formValues.organisation,
      phoneCountryCode: args.formValues.phoneCountryCode,
      phone: args.formValues.phone,
      email: args.formValues.email,
      address: {
        address1: args.formValues.address1 || "",
        address2: args.formValues.address2 || "",
        city: args.formValues.city || "",
        state: args.formValues.state || "",
        country: args.formValues.country || "",
        zipCode: args.formValues.zipCode || "",
        fullAddress: args.formValues.fullAddress || "",
        isManualAddress: args.formValues.isManualAddress || false,
      },
      qualifications: [...args.qualificationValues],
    };
    const response = await this.apiClient.protectedApi.post(`/user/organisation/contacts`, payload);
    const json = response.data;
    const contact = this.parseContact(json);
    return contact;
  }

  public async updateContact(args: {
    contactId: number;
    formValues: Partial<IContactPersonalInfoFormValues>;
    qualificationValues: IQualificationEntity[];
  }): Promise<IContactEntity> {
    const payload: Partial<IContactEntity> = {
      firstName: args.formValues.firstName,
      lastName: args.formValues.lastName,
      organisation: args.formValues.organisation,
      phoneCountryCode: args.formValues.phoneCountryCode,
      phone: args.formValues.phone,
      email: args.formValues.email,
      address: {
        address1: args.formValues.address1 || "",
        address2: args.formValues.address2 || "",
        city: args.formValues.city || "",
        state: args.formValues.state || "",
        country: args.formValues.country || "",
        zipCode: args.formValues.zipCode || "",
        fullAddress: args.formValues.fullAddress || "",
        isManualAddress: args.formValues.isManualAddress || false,
      },
      qualifications: [...args.qualificationValues],
    };

    const response = await this.apiClient.protectedApi.patch(`/user/organisation/contacts/${args.contactId}`, payload);
    const json = response.data;
    const contact = this.parseContact(json);
    return contact;
  }

  private parseContact(json: any): IContactEntity {
    const address: IAddressEntity = {
      address1: toString(get(json, "address.address1")),
      address2: toString(get(json, "address.address2")),
      city: toString(get(json, "address.city")),
      state: toString(get(json, "address.state")),
      country: toString(get(json, "address.country")),
      zipCode: toString(get(json, "address.zipCode")),
      fullAddress: toString(get(json, "address.fullAddress")),
      isManualAddress: Boolean(get(json, "address.isManualAddress")),
    };

    const qualificationsJSON = get(json, "qualifications") || [];
    const qualifications = qualificationsJSON.map((item: any) => {
      return this.parseQualificationFromJSON(item);
    });

    return {
      id: toInteger(get(json, "id")),
      firstName: toString(get(json, "firstName")),
      lastName: toString(get(json, "lastName")),
      organisation: toString(get(json, "organisation")),
      phoneCountryCode: toString(get(json, "phoneCountryCode")),
      phone: toString(get(json, "phone")),
      email: toString(get(json, "email")),
      address: address,
      lastUsed: toInteger(get(json, "lastUsed")),
      qualifications: qualifications,
    };
  }

  private parseQualificationFromJSON(json: any): IQualificationEntity {
    const qualification: IQualificationEntity = {
      qualificationName: toString(get(json, "qualificationName")),
      qualificationNumber: toUpper(toString(get(json, "qualificationNumber"))),
      licensingClasses: [],
      otherQualificationType: "",
    };
    const licensingClasses = get(json, "licensingClasses");
    if (licensingClasses && Array.isArray(licensingClasses)) {
      qualification.licensingClasses = licensingClasses;
    }
    qualification.otherQualificationType = toString(get(json, "otherQualificationType"));
    return qualification;
  }
}
