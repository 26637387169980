import React from "react";
import { capitalize, FormControl, FormHelperText, InputLabel, Paper } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import OutlinedInput from "../OutlinedInput/OutlinedInput";
import { serviceContainer } from "../../services/serviceContainer";
import trim from "lodash/trim";
import debounce from "lodash/debounce";
import { useFormikContext } from "formik";
import { IAddressEntity, IAddressSuggestionEntity } from "../../models/Address.model";
import { nanoid } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components/macro";

type FormikAddressSearchProps = {
  label: string;
  required: boolean;
};

const FormikAddressSearch: React.FC<FormikAddressSearchProps> = ({ required, label }) => {
  const { t } = useTranslation();
  const { setFieldValue, errors, values } = useFormikContext<IAddressEntity>();

  const [addressSearchInputValue, setAddressSearchInputValue] = React.useState(values.fullAddress);
  const [isOptionListOpen, setIsOptionListOpen] = React.useState(false);
  const [addressOptions, setAddressOptions] = React.useState<IAddressSuggestionEntity[]>([]);
  const inputId = React.useMemo(() => {
    return `address-${nanoid(5)}`;
  }, []);
  const isAutocompleteInputFocusedRef = React.useRef(false);

  React.useEffect(() => {
    setAddressSearchInputValue(values.fullAddress);
  }, [values.fullAddress]);

  // Address search
  const debouncedSearch = React.useMemo(
    () =>
      debounce(async (keywords: string) => {
        try {
          const options = await serviceContainer.cradle.addressCheckerService.search(keywords);

          // If autocomplete input is not focused anymore, we drop the result
          if (!isAutocompleteInputFocusedRef.current) {
            return;
          }

          setAddressOptions(options);
          setIsOptionListOpen(true);
        } catch (e) {
          serviceContainer.cradle.logger.error(e);
        }
      }, 1000),
    []
  );

  const debouncedLookupAddress = React.useCallback((keywords: string) => debouncedSearch(keywords), [debouncedSearch]);
  const handleAddressSearchInputChange = React.useCallback(
    (event: any, value: string, reason: string) => {
      setAddressSearchInputValue(value);

      if (!value || value.length < 2 || reason === "reset") {
        return;
      }

      debouncedLookupAddress(value);
    },
    [debouncedLookupAddress]
  );

  // Update form values
  const clearFormValues = React.useCallback(() => {
    const addressFields = ["address1", "address2", "city", "state", "country", "zipCode", "fullAddress"];
    for (const field of addressFields) {
      setFieldValue(field, "");
    }
  }, [setFieldValue]);

  const handleAddressSearchValueChange = React.useCallback(
    async (event: any, value: any, reason: string) => {
      setIsOptionListOpen(false);
      clearFormValues();

      if (!value) {
        return;
      }

      // Look up address details when selecting one option from autocomplete input
      const address = await serviceContainer.cradle.addressCheckerService.lookup(value.identifier);
      for (const [key, value] of Object.entries(address)) {
        setFieldValue(key, value);
      }
    },
    [clearFormValues, setFieldValue]
  );

  const handleAddressSearchInputBlur = React.useCallback(
    (evt: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setIsOptionListOpen(false);

      // If input value is empty, clear the address details
      if (!trim(evt.target.value)) {
        setAddressSearchInputValue("");
        clearFormValues();
        return;
      }

      // If input value is not empty, reset address details
      setAddressSearchInputValue(values.fullAddress);
    },
    [clearFormValues, values.fullAddress]
  );

  return (
    <FormControl
      fullWidth={true}
      hiddenLabel={true}
      variant={"outlined"}
      required={required}
      error={Boolean(errors["fullAddress"])}
    >
      <StyledInputLabel htmlFor={inputId} shrink={false}>
        {t(label)}
      </StyledInputLabel>
      <Autocomplete
        data-testid="FormikAddressInputAutocomplete"
        id={inputId}
        freeSolo={false}
        openOnFocus={false}
        selectOnFocus={false}
        blurOnSelect={true}
        onChange={handleAddressSearchValueChange}
        onInputChange={handleAddressSearchInputChange}
        inputValue={addressSearchInputValue}
        open={isOptionListOpen}
        PaperComponent={StyledAutocompletePopupPaper}
        ListboxComponent={StyledAutocompleteListbox}
        renderInput={(params) => {
          return (
            <div ref={params.InputProps.ref}>
              <OutlinedInput
                {...params.inputProps}
                fullWidth={true}
                type="search"
                autoComplete="off"
                onBlur={(evt) => {
                  isAutocompleteInputFocusedRef.current = false;

                  const inputProps: any = params.inputProps;
                  if (inputProps.onBlur) {
                    inputProps.onBlur(evt);
                  }

                  handleAddressSearchInputBlur(evt);
                }}
                onFocus={(evt) => {
                  isAutocompleteInputFocusedRef.current = true;

                  const inputProps: any = params.inputProps;
                  if (inputProps.onFocus) {
                    inputProps.onFocus(evt);
                  }
                }}
                error={Boolean(errors["fullAddress"])}
              />
            </div>
          );
        }}
        options={addressOptions}
        getOptionLabel={(option) => option.fullAddress}
        getOptionSelected={(option, value) => {
          return option.identifier === value.identifier;
        }}
      />

      {errors["fullAddress"] && (
        <FormHelperText error={true} data-testid={`InputError${capitalize("fullAddress")}`}>
          {errors["fullAddress"]}
        </FormHelperText>
      )}
    </FormControl>
  );
};

const StyledInputLabel = styled(InputLabel)(
  ({ theme }) => css`
    font-size: 16px;
    font-weight: 400;
    color: ${theme.palette.objective.dark.night};
  `
);

const StyledAutocompletePopupPaper = styled(Paper)(
  ({ theme }) => css`
    box-shadow: ${theme.shadows[6]};
  `
);

const StyledAutocompleteListbox = styled.ul(
  ({ theme }) => css`
    & > li {
      border-left: 4px solid transparent;
      padding-top: 8px;
      padding-bottom: 8px;
    }

    & > li:hover {
      border-left: 4px solid ${theme.palette.primary.main};
      background: ${theme.palette.objective.blue.light};
    }
  `
);

export default FormikAddressSearch;
