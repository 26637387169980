import React from "react";
import DetailsCard from "./DetailsCard";
import styled, { css } from "styled-components/macro";
import ParticipantQualificationList from "./ParticipantQualificationList";
import LabelledCheckbox from "../LabelledCheckbox/LabelledCheckbox";
import { useTranslation } from "react-i18next";
import FlexBox from "../FlexBox/FlexBox";
import {
  selectCurrentParticipant,
  selectCurrentQualifications,
  addQualification,
  IParticipantBufferEntity,
  removeQualification,
} from "store/app-state/participant-buffer/participantBuffer";
import { useStoreDispatch, useStoreSelector } from "store/hooks";
import Guard from "../Guard/Guard";
import QualificationViewContextProvider from "./contexts/QualificationViewContext";
import { IQualificationEntity } from "models/Qualification.model";

type ParticipantLicencesProps = {
  shouldSaveAsContact: boolean;
  shouldUpdateContactLibrary: boolean;
  onSaveAsContactChanged: (value: boolean) => void;
  onUpdateContactLibrary: (value: boolean) => void;
};

const ParticipantLicences: React.FC<ParticipantLicencesProps> = ({
  shouldSaveAsContact,
  shouldUpdateContactLibrary,
  onSaveAsContactChanged,
  onUpdateContactLibrary,
}) => {
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();
  const currentParticipant = useStoreSelector((state) => selectCurrentParticipant(state));
  const currentQualifications = useStoreSelector(selectCurrentQualifications);

  const handleAddQualification = React.useCallback(
    (values: IQualificationEntity) => {
      const qualification: IParticipantBufferEntity["qualifications"][0] = {
        qualificationName: values.qualificationName,
        licensingClasses: values.licensingClasses,
        qualificationNumber: values.qualificationNumber,
        otherQualificationType: values.otherQualificationType,
      };
      dispatch(addQualification(qualification));
    },
    [dispatch]
  );

  const handleRemoveQualification = React.useCallback(
    (index: number) => {
      dispatch(removeQualification({ index }));
    },
    [dispatch]
  );

  return (
    <StyledContainer>
      <FlexBox direction={"column"}>
        <DetailsCardContainer>
          <DetailsCard />
        </DetailsCardContainer>
        <QualificationViewContextProvider
          currentQualifications={currentQualifications}
          onAddQualification={handleAddQualification}
          onRemoveQualification={handleRemoveQualification}
        >
          <ParticipantQualificationList />
        </QualificationViewContextProvider>
      </FlexBox>
      <Guard condition={!currentParticipant.id}>
        <FlexBox direction={"column"}>
          <SaveToContactLibraryContainer>
            <LabelledCheckbox
              label={t(`Save this person to my Contact library`)}
              fieldName={"saveToLibrary"}
              selected={shouldSaveAsContact}
              onSelect={() => onSaveAsContactChanged(true)}
              onDeselect={() => onSaveAsContactChanged(false)}
            />
          </SaveToContactLibraryContainer>
        </FlexBox>
      </Guard>
      <Guard condition={currentParticipant.id}>
        <FlexBox direction={"column"}>
          <SaveToContactLibraryContainer>
            <LabelledCheckbox
              label={t(`Save changes to this contact in my Contact library`)}
              fieldName={"updateContact"}
              selected={shouldUpdateContactLibrary}
              onSelect={() => onUpdateContactLibrary(true)}
              onDeselect={() => onUpdateContactLibrary(false)}
            />
          </SaveToContactLibraryContainer>
        </FlexBox>
      </Guard>
    </StyledContainer>
  );
};

const StyledContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
  `
);

const DetailsCardContainer = styled.div(
  ({ theme }) => css`
    padding: 24px 48px;
    display: flex;
    flex-direction: column;
    gap: ${theme.mixins.flexGap("8px")};
    border-bottom: 2px solid ${theme.palette.objective.light.daylight};
  `
);

const SaveToContactLibraryContainer = styled.div(
  ({ theme }) => css`
    padding: 24px 48px;
    display: flex;
    flex-direction: column;
    gap: ${theme.mixins.flexGap("8px")};
    border-top: 2px solid ${theme.palette.objective.light.daylight};
  `
);

export default ParticipantLicences;
