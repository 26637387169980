import { IParticipantService } from "services/participant/ParticipantService.types";
import {
  InvitationStatus,
  IParticipantEntity,
  parseInvitationStatus,
  parseParticipantAccessLevelString,
} from "models/Participant.model";
import { Cradle } from "services/serviceContainer.types";
import get from "lodash/get";
import toInteger from "lodash/toInteger";
import toString from "lodash/toString";
import toArray from "lodash/toArray";
import toUpper from "lodash/toUpper";
import { IAddressEntity } from "models/Address.model";
import { NotificationLevel } from "models/NotificationSubscriptionLevel.model";
import { ServiceError, ServiceErrorCode } from "services/ServiceError";
import { IQualificationEntity } from "models/Qualification.model";

export class ParticipantService implements IParticipantService {
  private readonly i18n: Cradle["i18n"];
  private readonly apiClient: Cradle["apiClient"];
  private readonly contactService: Cradle["contactService"];

  /**
   * Dependencies will be injected
   * @param args
   */
  constructor(args: Pick<Cradle, "i18n" | "apiClient" | "contactService">) {
    this.i18n = args.i18n;
    this.apiClient = args.apiClient;
    this.contactService = args.contactService;
  }

  public async fetchParticipantsByApplicationId(applicationId: number): Promise<IParticipantEntity[]> {
    const response = await this.apiClient.protectedApi.get(`/user/applications/${applicationId}/participantsV2`);
    const participantsJson = get(response.data, "participants");
    const primaryContactId = get(response.data, "primaryContactId");
    if (!Array.isArray(participantsJson)) {
      throw new ServiceError(ServiceErrorCode.ServerError, this.i18n.t("Fail to fetch participants"));
    }

    const participants = this.parseParticipantsFromJSON(applicationId, participantsJson, primaryContactId);
    return participants;
  }

  public async removeParticipantFromApplication(applicationId: number, participantId: number): Promise<void> {
    await this.apiClient.protectedApi.delete(`/user/applications/${applicationId}/participants/${participantId}`);
  }

  public async createParticipant(applicationId: number, payload: Object): Promise<IParticipantEntity> {
    const response = await this.apiClient.protectedApi.post(
      `/user/applications/${applicationId}/participantsV2`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    const json = response.data;
    const participant = this.parseParticipantFromJSON(applicationId, json);
    return participant;
  }

  public async updateParticipant(
    applicationId: number,
    participantId: number,
    payload: Object
  ): Promise<IParticipantEntity> {
    const response = await this.apiClient.protectedApi.patch(
      `/user/applications/${applicationId}/participantsV2/${participantId}`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    const json = response.data;
    const participant = this.parseParticipantFromJSON(applicationId, json);
    return participant;
  }

  public async sendParticipantInvite(applicationId: number, participantId: number): Promise<IParticipantEntity> {
    const response = await this.apiClient.protectedApi.patch(
      `/user/applications/${applicationId}/participantsV2/${participantId}`,
      { invitation: InvitationStatus.Queued },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    const json = response.data;
    const participant = this.parseParticipantFromJSON(applicationId, json);
    return participant;
  }

  public async updateParticipantNotificationLevel(
    applicationId: number,
    participantId: number,
    level: NotificationLevel
  ): Promise<IParticipantEntity> {
    const response = await this.apiClient.protectedApi.patch(
      `/user/applications/${applicationId}/participants/${participantId}/notification-level`,
      {
        notificationLevel: level.toString(),
      }
    );
    const json = response.data;
    const participant = this.parseParticipantFromJSON(applicationId, json);
    return participant;
  }

  public async fetchParticipant(applicationId: number, participantId: number): Promise<IParticipantEntity> {
    const response = await this.apiClient.protectedApi.get(
      `/user/applications/${applicationId}/participantsV2/${participantId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    const json = response.data;
    const participant = this.parseParticipantFromJSON(applicationId, json);
    return participant;
  }

  public parseParticipantsFromJSON(applicationId: number, json: any, primaryContactId?: number): IParticipantEntity[] {
    let participants: IParticipantEntity[] = [];
    for (const item of json) {
      const participant = this.parseParticipantFromJSON(applicationId, item, primaryContactId);
      participants.push(participant);
    }

    return participants;
  }

  public parseParticipantFromJSON(applicationId: number, json: any, primaryContactId?: number): IParticipantEntity {
    const address: IAddressEntity = {
      address1: toString(get(json, "address.address1")),
      address2: toString(get(json, "address.address2")),
      city: toString(get(json, "address.city")),
      state: toString(get(json, "address.state")),
      country: toString(get(json, "address.country")),
      zipCode: toString(get(json, "address.zipCode")),
      fullAddress: toString(get(json, "address.fullAddress")),
      isManualAddress: Boolean(get(json, "address.isManualAddress")),
    };

    // V2 API has qualifications; V1 does not have
    const qualificationsJSON = get(json, "qualifications") || [];
    const qualifications = qualificationsJSON.map((item: any) => {
      return this.parseQualificationFromJSON(item);
    });

    const participant: IParticipantEntity = {
      id: toInteger(get(json, "id")),
      loginUserId: toInteger(get(json, "loginUserId")),
      applicationId: applicationId,
      firstName: toString(get(json, "firstName")),
      lastName: toString(get(json, "lastName")),
      groupList: toString(get(json, "groupList")),
      groupName: toString(get(json, "groupName")),
      organisation: toString(get(json, "organisation")),
      phoneCountryCode: toString(get(json, "phoneCountryCode")),
      phone: toString(get(json, "phone")),
      email: toString(get(json, "email")),
      address: address,
      participantTypes: toArray(get(json, "participantTypes")).map((item) => ({
        id: toInteger(get(item, "id")),
        name: toString(get(item, "name")),
        displayName: toString(get(item, "displayName")),
        displayOrder: toInteger(get(item, "displayOrder")),
        required: Boolean(get(item, "required")),
      })),
      accessLevel: parseParticipantAccessLevelString(toString(get(json, "accessLevel"))),
      invitation: parseInvitationStatus(toString(get(json, "invitation"))),
      qualifications,
    };

    if (primaryContactId) {
      participant.primaryContact = participant.id === primaryContactId;
    }
    return participant;
  }

  private parseQualificationFromJSON(json: any): IQualificationEntity {
    const qualification: IQualificationEntity = {
      qualificationName: toString(get(json, "qualificationName")),
      qualificationNumber: toUpper(toString(get(json, "qualificationNumber"))),
      licensingClasses: [],
      otherQualificationType: "",
    };
    const licensingClasses = get(json, "licensingClasses");
    if (licensingClasses && Array.isArray(licensingClasses)) {
      qualification.licensingClasses = licensingClasses;
    }
    qualification.otherQualificationType = toString(get(json, "otherQualificationType"));
    return qualification;
  }
}
