import React from "react";
import { IQualificationEntity, QualificationType } from "../../models/Qualification.model";
import FlexBox from "../FlexBox/FlexBox";
import { IconButton, useTheme } from "@material-ui/core";
import Box from "../Box/Box";
import FontIcon from "../FontIcon/FontIcon";
import { QualificationViewContext } from "components/ParticipantEditDialog/contexts/QualificationViewContext";
import styled, { css } from "styled-components/macro";

type QualificationCardProps = {
  qualification: IQualificationEntity;
  index: number;
};

const QualificationCard: React.FC<QualificationCardProps> = ({ qualification, index }) => {
  const theme = useTheme();
  const { removeQualification } = React.useContext(QualificationViewContext);

  const handleDeleteClick = React.useCallback(() => {
    removeQualification(index);
  }, [removeQualification, index]);

  const mappedQualificationName = React.useMemo(() => {
    if (qualification.otherQualificationType) {
      return qualification.otherQualificationType;
    }
    switch (qualification.qualificationName) {
      case "LBP":
        return QualificationType.LicensedBuildingPractitioner;
      default:
        return qualification.qualificationName;
    }
  }, [qualification.otherQualificationType, qualification.qualificationName]);

  return (
    <FlexBox flexDirection="row" data-testid="QualificationCard">
      <FlexBox
        bgcolor={theme.palette.objective.light.day}
        flexDirection={"column"}
        spacing={2}
        paddingX={"16px"}
        paddingY={"12px"}
        flexGrow={1}
        flexWrap={"wrap"}
      >
        <Box fontWeight={600}>{mappedQualificationName}</Box>
        <Box>ID: {qualification.qualificationNumber}</Box>
        <FlexBox>Classes: {qualification.licensingClasses.join(", ")}</FlexBox>
      </FlexBox>
      <StyledIconButton onClick={handleDeleteClick} data-testid={"DeleteQualificationButton"}>
        <Box paddingX={"24px"}>
          <FontIcon name={"icon-close"} fontSize={14} color={theme.palette.objective.red.main} />
        </Box>
      </StyledIconButton>
    </FlexBox>
  );
};

const StyledIconButton = styled(IconButton)(
  ({ theme }) => css`
    border-radius: 0;
    &:hover {
      background-color: ${theme.palette.objective.red.light};
    }
  `
);

export default QualificationCard;
